import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          <path d="M2420 3660 c0 -45 3 -60 13 -57 6 2 11 11 9 21 -4 23 15 20 28 -4 6
-11 17 -20 25 -20 20 0 19 10 -6 36 l-21 23 22 28 c14 17 17 29 10 31 -6 2
-18 -5 -26 -17 -18 -25 -36 -28 -32 -5 2 10 -3 19 -9 21 -10 3 -13 -12 -13
-57z"/>
          <path d="M2664 3655 c-8 -54 6 -75 46 -75 22 0 30 6 39 31 13 38 14 82 2 75
-5 -3 -12 -22 -15 -41 -7 -38 -32 -57 -46 -35 -4 7 -5 30 -3 51 4 24 2 39 -5
39 -6 0 -14 -20 -18 -45z"/>
          <path d="M2907 3583 c-17 -45 -17 -48 0 -60 18 -14 43 -17 43 -7 0 4 -7 12
-16 18 -15 11 -15 15 0 50 19 46 19 46 3 46 -7 0 -21 -21 -30 -47z"/>
          <path d="M3127 3534 c-3 -3 -1 -14 5 -24 9 -14 6 -24 -12 -49 -15 -22 -19 -36
-12 -43 7 -7 17 3 34 31 17 31 27 39 42 35 28 -9 16 14 -20 36 -17 11 -33 17
-37 14z"/>
          <path d="M3297 3352 l-37 -38 25 -24 c30 -28 46 -19 19 11 l-19 21 33 -7 c32
-7 33 -6 21 14 -16 25 -5 39 16 21 23 -19 31 -3 10 20 -10 11 -21 20 -25 20
-3 0 -23 -17 -43 -38z"/>
          <path d="M1475 3260 c-11 -4 -33 -6 -50 -4 -29 4 -30 4 -5 -6 14 -6 35 -23 47
-38 12 -15 22 -22 23 -16 0 6 -7 17 -15 24 -21 18 -19 25 13 37 15 6 23 11 17
11 -5 0 -19 -3 -30 -8z"/>
          <path d="M3446 3184 c-32 -31 -7 -94 37 -94 24 0 21 14 -5 25 -27 12 -30 43
-5 59 21 14 53 -4 49 -27 -4 -21 17 -32 24 -13 3 9 -1 27 -10 41 -19 29 -66
34 -90 9z"/>
          <path d="M1366 3044 c-28 -28 -15 -95 19 -94 5 0 2 5 -7 11 -25 14 -23 65 2
79 51 27 95 -36 50 -72 -17 -13 -18 -18 -6 -18 17 0 36 29 36 54 0 22 -36 56
-59 56 -11 0 -27 -7 -35 -16z"/>
          <path d="M3597 2964 c-26 -14 -45 -29 -42 -34 4 -7 13 -7 25 0 13 7 21 5 31
-6 13 -15 5 -26 -25 -37 -5 -2 -6 -8 -2 -15 5 -9 21 -5 57 13 55 28 66 50 19
40 -25 -6 -30 -4 -30 13 0 11 7 22 15 26 15 5 21 26 8 26 -5 -1 -30 -12 -56
-26z"/>
          <path d="M1356 2782 c-2 -4 12 -6 32 -4 56 5 62 10 15 11 -23 1 -44 -3 -47 -7z" />
          <path d="M1341 2749 c-1 -27 25 -40 71 -36 l43 4 -48 1 c-39 2 -49 6 -57 25
-6 15 -9 17 -9 6z"/>
          <path d="M1405 2545 c-37 -12 -38 -14 -11 -14 l28 -1 -17 -27 c-17 -27 -17
-27 0 -11 14 13 22 14 35 6 25 -16 50 -3 49 24 l-2 23 -4 -23 c-3 -17 -10 -22
-26 -20 -31 4 -29 40 3 49 19 5 20 7 5 7 -11 0 -38 -6 -60 -13z"/>
          <path d="M1562 2173 c-24 -14 -41 -28 -38 -31 3 -2 17 3 32 12 34 23 39 15 19
-24 -9 -18 -14 -34 -11 -37 2 -3 24 7 48 21 24 14 38 26 33 26 -6 0 -21 -7
-33 -16 -20 -14 -22 -14 -22 1 0 9 5 25 11 36 18 35 6 38 -39 12z"/>
          <path d="M1141 2060 c-2 -27 51 -172 75 -208 23 -34 24 -34 24 -9 0 14 -6 28
-12 30 -10 4 -10 6 0 6 7 1 17 -11 23 -25 5 -15 12 -24 15 -21 6 6 -19 85 -42
129 -23 44 -9 47 17 4 12 -20 24 -35 27 -32 3 3 -8 25 -24 48 -16 24 -35 54
-42 68 -12 25 -12 25 -7 -5 4 -22 3 -26 -5 -15 -6 10 -10 11 -10 3 0 -23 -18
-14 -28 15 -6 15 -11 20 -11 12z"/>
          <path d="M1160 2050 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
          <path d="M1702 1984 c-12 -8 -22 -23 -21 -32 0 -13 2 -12 9 4 12 30 39 37 66
19 28 -18 31 -41 7 -67 -9 -10 -11 -18 -5 -18 17 0 34 39 28 64 -3 13 -17 28
-31 34 -32 15 -26 15 -53 -4z"/>
          <path d="M1680 1924 c0 -17 15 -31 40 -38 23 -6 23 -6 5 5 -11 6 -26 19 -32
27 -7 9 -13 12 -13 6z"/>
          <path d="M3670 1811 c-9 -22 -9 -34 0 -50 15 -29 22 -27 9 3 -15 32 3 63 38
69 27 4 27 4 -3 6 -26 1 -34 -4 -44 -28z"/>
          <path d="M1913 1824 c4 -4 -7 -25 -24 -48 l-31 -41 36 37 c37 39 45 58 24 58
-6 0 -8 -3 -5 -6z"/>
          <path d="M3744 1798 c-12 -14 -13 -18 -3 -10 9 6 19 9 22 5 4 -3 7 1 7 10 0
22 -3 21 -26 -5z"/>
          <path d="M3752 1760 c-6 -10 -22 -20 -34 -23 -22 -4 -22 -4 -2 -6 25 -1 72 33
58 42 -6 3 -15 -3 -22 -13z"/>
          <path d="M2026 1678 c-28 -48 -22 -68 24 -68 37 0 64 32 56 65 -4 14 -11 25
-16 25 -6 0 -5 -9 2 -20 18 -28 -1 -55 -38 -55 -40 0 -42 25 -5 63 17 19 23
32 16 32 -8 0 -25 -19 -39 -42z"/>
          <path d="M3520 1697 c0 -5 8 -22 17 -38 15 -25 16 -33 5 -50 -11 -18 -11 -20
0 -17 21 7 21 46 -1 81 -11 18 -21 28 -21 24z"/>
          <path d="M3467 1639 c10 -17 24 -36 32 -42 8 -7 2 7 -14 31 -32 49 -45 57 -18
11z"/>
          <path d="M2287 1594 c-3 -4 -3 -19 0 -35 4 -23 2 -29 -12 -29 -12 0 -16 6 -12
19 6 24 -14 18 -31 -9 -10 -17 -9 -20 5 -18 38 3 52 -3 56 -24 2 -13 5 5 6 40
1 62 0 68 -12 56z"/>
          <path d="M3280 1585 c0 -5 7 -29 16 -52 16 -41 44 -57 58 -34 4 5 -2 7 -12 4
-15 -3 -24 6 -40 44 -12 26 -21 44 -22 38z"/>
          <path d="M2478 1533 c8 -3 11 -17 7 -41 -4 -30 -3 -32 4 -12 15 41 13 60 -6
59 -11 0 -13 -3 -5 -6z"/>
          <path d="M3083 1532 c-9 -6 -9 -19 2 -57 7 -28 14 -40 14 -28 1 12 9 24 20 28
24 7 35 35 20 49 -9 8 -10 7 -6 -5 6 -18 -10 -39 -30 -39 -20 0 -16 48 5 53
12 3 13 5 2 6 -8 0 -20 -3 -27 -7z"/>
          <path d="M2682 1454 c-13 -26 -21 -48 -18 -51 2 -2 12 14 22 36 20 46 29 45
38 -4 4 -19 10 -37 15 -39 4 -3 1 19 -7 49 -8 30 -18 55 -22 55 -3 0 -16 -21
-28 -46z"/>
          <path d="M2756 1153 c-15 -15 -3 -23 33 -23 22 0 47 -3 57 -7 16 -6 16 -7 -2
-15 -19 -8 -19 -8 0 -5 36 4 29 26 -11 33 -21 4 -45 10 -54 15 -9 5 -19 5 -23
2z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
